'use client';

import { useState, useEffect } from 'react';

const useSearchParams = () => {
	const [searchParams, setSearchParams] = useState(() => {
		// Check if window is defined
		if (typeof window !== 'undefined') {
			return new URLSearchParams(window.location.search);
		}
		// Return an empty URLSearchParams instance if window is not defined
		return new URLSearchParams();
	});

	useEffect(() => {
		// Ensure window is defined before attaching event listeners
		if (typeof window !== 'undefined') {
			let previousSearch = window.location.search;

			const checkForUpdates = () => {
				const currentSearch = window.location.search;
				if (previousSearch !== currentSearch) {
					setSearchParams(new URLSearchParams(currentSearch));
					previousSearch = currentSearch;
				}
			};

			// Poll for changes to the URL's search parameters every 100ms
			const intervalId = setInterval(checkForUpdates, 100);

			// Cleanup: clear the interval when the component unmounts
			return () => clearInterval(intervalId);
		}
	}, []);

	return searchParams;
};

export default useSearchParams;
