'use client';

import Login from '@/components/auth/Login';
import { partnerStorageName } from '@/services/apis/config';
import useSearchParams from '@/services/hooks/useSearchParams';
import { usePathname, useRouter } from 'next/navigation';
import React, { createContext, useContext, useEffect, useState } from 'react';
import useAuth from './AuthProvider';

interface LoginModelContextType {
	showLoginModal: boolean;
	setShowLoginModal: React.Dispatch<React.SetStateAction<boolean>>;
	setGoTo: React.Dispatch<React.SetStateAction<string | null>>;
	searchValue: string;
	setSearchValue: React.Dispatch<React.SetStateAction<string>>;
}

const LoginModelContext = createContext<LoginModelContextType | undefined>(undefined);

interface LoginModelProviderProps {
	children: React.ReactNode;
}

export const LoginModelProvider = ({ children }: LoginModelProviderProps) => {
	const [showLoginModal, setShowLoginModal] = useState(false);
	const [goTo, setGoTo] = useState<string | null>(null);
	const { user, isLogged }: any = useAuth();
	const [thirdPartyRef, setThirdPartyRef] = useState({}) as any;
	const router = useRouter();
	const pathname = usePathname();
	const params = useSearchParams();
	// let params;

	const [searchValue, setSearchValue]: any = useState<string>(null);

	const handleThirdPartyRef = () => {
		const isThirdPartyRef = params.has('refPartner');
		let query = {};
		if (!isThirdPartyRef) {
			const thirdPartyRef = JSON.parse(localStorage.getItem(partnerStorageName) || '{}');
			query = thirdPartyRef;
		}

		params.forEach((value, key) => {
			query[key] = value;
		});
		const thirdPartyParams = [
			'refPartner',
			'mobile',
			'mobileChangable',
			'showLogin',
			'initialOTPSend',
			'autoLogin',
			'token',
			'appNotifyBanner'
		];
		if (query.hasOwnProperty('refPartner')) {
			localStorage.setItem(partnerStorageName, JSON.stringify(query));
			setThirdPartyRef(query);
			const newQuery = {};
			Object.keys(query).forEach((key) => {
				if (!thirdPartyParams.includes(key)) {
					newQuery[key] = query[key];
				}
			});
			const buildQuery = (obj: any) => {
				const queryString = Object.keys(obj)
					.map((key) => key + '=' + obj[key])
					.join('&');
				return queryString;
			};
			const outputQuery = buildQuery(newQuery);
			router.replace(`${pathname}?${outputQuery}`, {
				scroll: false
			});
		}
	};

	useEffect(() => {
		handleThirdPartyRef();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [params]);

	useEffect(() => {
		if (isLogged) {
			setShowLoginModal(false);
			const localthirdPartyRef = JSON.parse(localStorage.getItem(partnerStorageName) || '{}');
			if (localthirdPartyRef) {
				const { refPartner, appNotifyBanner } = localthirdPartyRef;
				localStorage.setItem(
					partnerStorageName,
					JSON.stringify({
						refPartner,
						appNotifyBanner
					})
				);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isLogged, thirdPartyRef]);

	useEffect(() => {
		if (pathname.startsWith('/search')) {
			setSearchValue(params.get('_search') || '');
		} else {
			setSearchValue('');
		}
		setGoTo(null);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [pathname]);

	return (
		<LoginModelContext.Provider
			value={{
				showLoginModal,
				setShowLoginModal,
				setGoTo,
				searchValue,
				setSearchValue
			}}>
			{children}
			{!isLogged && (
				<Login
					showLoginModal={showLoginModal}
					setShowLoginModal={setShowLoginModal}
					thirdPartyRef={thirdPartyRef}
					setThirdPartyRef={setThirdPartyRef}
					goTo={goTo}
				/>
			)}
		</LoginModelContext.Provider>
	);
};

export const useLoginModel = (): LoginModelContextType => {
	const context = useContext(LoginModelContext);
	if (context === undefined) {
		throw new Error('useLoginModel must be used within a LoginModelProvider');
	}
	return context;
};

export default useLoginModel;
