'use client';
import React, { useState, ReactElement, useEffect } from 'react';
import styles from './Accordion.module.scss';
import cls from 'classnames';
interface AccordionProps {
	title: React.ReactNode;
	children: React.ReactNode;
	titleClassName?: string;
	className?: string;
	contentClassName?: string;
	border?: boolean;
	expandIcon?: ReactElement; // Add prop for expand icon
	collapseIcon?: ReactElement; // Add prop for collapse icon
	expanded?: boolean;
}

const Accordion: React.FC<AccordionProps> = ({
	title,
	children,
	titleClassName,
	className,
	border = true,
	contentClassName,
	expandIcon, // De-structure expand icon prop
	collapseIcon, // De-structure collapse icon prop
	expanded = false
}) => {
	const [isOpen, setIsOpen] = useState(false);

	const toggleAccordion = () => {
		setIsOpen(!isOpen);
	};

	useEffect(() => {
		setIsOpen(expanded);
	}, [expanded]);

	return (
		<div className={cls(styles.accordion, className, border ? 'border1-grey300' : 'border-bottom-0-grey300')}>
			<div className={styles.accordionTitle} onClick={toggleAccordion}>
				<span className={titleClassName}>{title}</span>
				<span className='text-24 text-grey500'>{isOpen ? collapseIcon || '-' : expandIcon || '+'}</span>{' '}
			</div>
			{isOpen && (
				<div
					className={cls(
						styles.accordionContent,
						contentClassName,
						border ? 'border-t1-grey300' : 'border-t0-grey300'
					)}>
					{children}
				</div>
			)}
		</div>
	);
};

export default Accordion;
