import React from 'react';
import classNames from 'classnames';
import styles from './alert.module.scss';
import { FaCheckCircle, FaTimesCircle, FaExclamationCircle, FaInfoCircle } from 'react-icons/fa';

interface AlertProps {
	type: 'success' | 'error' | 'warning';
	message: string;
}

const Alert: React.FC<AlertProps> = ({ type, message }) => {
	const alertClasses = classNames({
		[styles.alert]: true,
		[styles.success]: type === 'success',
		[styles.error]: type === 'error',
		[styles.warning]: type === 'warning'
	});
	const getIcon = () => {
		switch (type) {
			case 'success':
				return <FaCheckCircle />;
			case 'error':
				return <FaInfoCircle />;
			case 'warning':
				return <FaExclamationCircle />;
			default:
				return <FaInfoCircle />;
		}
	};

	return <div className={alertClasses}>{message}</div>;
};

export default Alert;
