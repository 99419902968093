import { ProgressBar } from 'react-loader-spinner';
import styles from './loading.module.scss';
import cls from 'classnames';
import { useEffect, useRef } from 'react';

const PaymentLoading = () => {
	return (
		<div className={cls(styles.payment_loading, 'd-flex align-center justify-center')}>
			<div className={styles.payment_loading_overlay}>
				<div className={styles.payment_loading_spinner}>
					<ProgressBar
						visible={true}
						borderColor='#14ece5'
						ariaLabel='progress-bar-loading'
						wrapperStyle={{
							width: '100%',
							height: '200px'
						}}
						barColor='#ffffff'
						wrapperClass='d-flex align-center justify-center'
					/>
					<div className='text-18 text-white text-center mt-20'>Paymnet is being processed please wait...</div>
				</div>
			</div>
		</div>
	);
};

export default PaymentLoading;
