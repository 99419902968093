'use client';

import Accordion from '@/components/atoms/Accordion';
import Modal from '@/components/atoms/Modal';
import SpinnerWrapper from '@/components/atoms/SpinnerWrapper';
import UploadPrescriptionButton from '@/components/atoms/UploadPrescriptionButton';
import { useAlert } from '@/contexts/AlertProvider';
import useCart from '@/contexts/CartProvider';
import cls from 'classnames';
import { useState } from 'react';
import { AiFillLock } from 'react-icons/ai';
import { BiNews } from 'react-icons/bi';
import { FaMobileScreenButton } from 'react-icons/fa6';
import { MdAssignment, MdEventAvailable, MdInfo } from 'react-icons/md';
import ValidPrescriptionModal from '../ValidPrescriptionModal';

const UploadPrescriptionModal = ({ open, setOpen, toggle }) => {
	const alert = useAlert();
	const [isLoading, setLoading] = useState(false);
	const [isOpen, setIsOpen] = useState(false);
	const onClose = () => setIsOpen(false);
	const onConfirm = () => {
		setIsOpen(false);
	};
	const { toggleDrawer, getCart, drawerOpen, setDrawerOpen } = useCart();

	const faqs = [
		{
			icon: <MdEventAvailable size={24} className='text-grey600 text-24 mr-10' />,
			title: 'কেন প্রেসক্রিপশনের ছবি আপলোড করবেন?'
		},
		{
			icon: <FaMobileScreenButton size={24} className='text-grey600 text-24 mr-10' />,
			title: 'প্রেসক্রিপশন হারানোর ভয় থেকে আপনি মুক্ত, আজীবনের জন্য আরোগ্য অ্যাপে আপনি খুজে পাবেন আপনার ডিজিটাল প্রেসক্রিপশন।'
		},
		{
			icon: <MdAssignment size={24} className='text-grey600 text-24 mr-10' />,
			title: "ডাক্তারের হাতের লেখা বুঝা না গেলেও কোন সমস্যা নেই, আমাদের 'A' গ্রেড ফার্মাসিস্ট প্রেস্ক্রিপশন দেখে ঔষধ অর্ডারে আপনাকে সহযোগিতা করবে।"
		},
		{
			icon: <AiFillLock size={24} className='text-grey600 text-24 mr-10' />,
			title: 'আপনার প্রেসক্রিপশন কখনো তৃতীয় কোন পক্ষের সাথে শেয়ার করা হবে না।'
		},
		{
			icon: <BiNews size={24} className='text-grey600 text-24 mr-10' />,
			title: 'বাংলাদেশ সরকারের নিয়ম অনুসারে, কিছু মেডিসিন অর্ডার করার জন্য প্রেসক্রিপশন থাকা বাধ্যতামুলক।'
		}
	];
	return (
		<Modal
			isOpen={open}
			onClose={toggle}
			title='Upload Prescription'
			width={600}
			footer={null}
			bodyStyle={{
				padding: '0px 20px 20px 20px'
			}}>
			<SpinnerWrapper loading={isLoading}>
				<Accordion
					expanded={true}
					title='কেন প্রেসক্রিপশনের ছবি আপলোড করবেন?'
					border={true}
					titleClassName='text-16 text-primary fw-600'>
					{faqs.map((faq, q) => (
						<div className='d-flex items-center mb-20 pl-20' key={q}>
							{faq.icon}
							<span>{faq.title}</span>
						</div>
					))}
				</Accordion>

				<UploadPrescriptionButton
					className='my-20 w-full'
					addToCart={1}
					onSuccess={(message) => {
						toggleDrawer();
						setOpen(false);
						alert.success({
							message: 'Prescription uploaded successfully',
							provider: 'toast'
						});
						getCart();
						!drawerOpen && setDrawerOpen(true);
					}}
					onError={(message) => {
						alert.error({
							message: message
						});
					}}
					setLoading={setLoading}
				/>

				<ValidPrescriptionModal isOpen={isOpen} onClose={onClose} onConfirm={onConfirm} />

				<div
					className={cls('mb-10 d-flex gap-10 border1-grey300 cursor-pointer p-10 rounded-6 items-center')}
					onClick={() => setIsOpen(true)}>
					<MdInfo className='text-24 text-grey500' />
					<div className='text-16 text-orange fw-500'>Valid Prescription Guide</div>
				</div>
			</SpinnerWrapper>
		</Modal>
	);
};

export default UploadPrescriptionModal;
