import { IC_CHEVRON_LEFT, IC_CHEVRON_RIGHT } from '@/assets/icons';
import Modal from '@/components/atoms/Modal';
import Image from 'next/image';
import React, { useRef, useState } from 'react';
import { Autoplay, Controller, FreeMode, Navigation, Pagination, Thumbs } from 'swiper/modules';
import { Swiper, SwiperRef, SwiperSlide } from 'swiper/react';

import {
	IM_PRESCRIPTION_1,
	IM_PRESCRIPTION_2,
	IM_PRESCRIPTION_3,
	IM_PRESCRIPTION_4,
	IM_PRESCRIPTION_5
} from '@/assets/images';
import styles from './styles.module.scss';

interface Props {
	isOpen: boolean;
	onClose: () => void;
	onConfirm: () => void;
}

const ValidPrescriptionModal: React.FC<Props> = ({ isOpen, onClose, onConfirm }) => {
	const [thumbsSwiper, setThumbsSwiper] = useState(null);
	const swiperRef = useRef<SwiperRef | null>(null);
	const [allowSlide, setAllowSlide] = useState({
		allowSlidePrev: false,
		allowSlideNext: true
	});
	const data = [IM_PRESCRIPTION_1, IM_PRESCRIPTION_2, IM_PRESCRIPTION_3, IM_PRESCRIPTION_4, IM_PRESCRIPTION_5];
	const handleClose = () => {
		onClose();
		setThumbsSwiper(null);
	};

	if (!isOpen) {
		return null;
	}

	return (
		<Modal
			isOpen={isOpen}
			onClose={handleClose}
			title='Valid prescription guide'
			footer={null}
			fullHeight={true}
			width={665}>
			<div className='w-full p-20'>
				<Swiper
					ref={swiperRef}
					onSlideChange={(e) => {
						setAllowSlide({
							allowSlidePrev: !e.isBeginning,
							allowSlideNext: !e.isEnd
						});
					}}
					style={{
						overflow: 'hidden',
						position: 'relative'
					}}
					modules={[Autoplay, Pagination, Controller, Thumbs]}
					thumbs={{ swiper: thumbsSwiper }}
					slidesPerView={1}
					spaceBetween={20}
					autoplay={false}
					pagination={false}>
					{data?.map((d: any, i: number) => (
						<SwiperSlide key={i}>
							<Image
								src={d}
								style={{
									width: '100%',
									height: '522px',
									borderRadius: '6px',
									cursor: 'pointer',
									objectFit: 'cover'
								}}
								alt=''
								width={625}
								height={522}
							/>
						</SwiperSlide>
					))}
				</Swiper>
			</div>
			<div className='px-20'>
				<div className='text-16 text-grey900 fw-600 mb-10'>What is a valid prescription?</div>
				<div className='text-14 text-grey900'>
					A valid prescription should contain doctor, patient, medicine details & the doctor’s signature.
				</div>
			</div>
			<div className={styles.prescription_slider}>
				<div
					className='d-flex '
					style={{
						justifyContent: 'end'
					}}>
					<button
						className={styles.previous}
						disabled={!allowSlide.allowSlidePrev}
						onClick={() => {
							const swiperInstance = swiperRef.current?.swiper;
							swiperInstance?.slidePrev();
						}}>
						<IC_CHEVRON_LEFT />
					</button>
					<button
						className={styles.next}
						disabled={!allowSlide.allowSlideNext}
						onClick={() => {
							const swiperInstance = swiperRef.current?.swiper;
							swiperInstance?.slideNext();
						}}>
						<IC_CHEVRON_RIGHT />
					</button>
				</div>
			</div>

			<div>
				<Swiper
					className='thumbSlider'
					modules={[FreeMode, Navigation, Thumbs]}
					onSwiper={setThumbsSwiper}
					freeMode={true}
					watchSlidesProgress={true}
					style={{
						paddingInline: '10px',
						paddingBottom: '8px'
					}}
					autoplay={{
						delay: 2500,
						disableOnInteraction: false,
						pauseOnMouseEnter: true
					}}
					slidesPerView={8}>
					{data?.map((d: any, i: number) => (
						<SwiperSlide key={i}>
							<Image
								src={d?.src}
								style={{
									width: '70px',
									height: '70px',
									borderRadius: '6px',
									cursor: 'pointer',
									objectFit: 'cover'
								}}
								alt=''
								width={70}
								height={70}
							/>
						</SwiperSlide>
					))}
				</Swiper>
			</div>
		</Modal>
	);
};

export default ValidPrescriptionModal;
