import cls from 'classnames';
import React, {
	ChangeEvent,
	HTMLInputTypeAttribute,
	InputHTMLAttributes,
	TextareaHTMLAttributes,
	forwardRef
} from 'react';
import styles from './input.module.scss';

interface BaseProps {
	type?: HTMLInputTypeAttribute;
	name?: string;
	placeholder?: string;
	accept?: string;
	required?: boolean;
	className?: string;
	onChange?: (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
	multiline?: boolean;
	handleHasFormFile?: any;
}

// Extend the BaseProps with the HTML attributes for the respective elements
interface InputProps extends BaseProps, Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange'> {}
interface TextareaProps extends BaseProps, Omit<TextareaHTMLAttributes<HTMLTextAreaElement>, 'onChange'> {
	multiline?: boolean;
}

// Union type for component props
type Props = InputProps | TextareaProps;

const Input = forwardRef<HTMLInputElement | HTMLTextAreaElement, Props>(
	(
		{ type = 'text', name, defaultValue, placeholder, accept, onChange, required, className, multiline, ...props },
		ref
	) => {
		const [fileName, setFileName] = React.useState('');

		const isTextarea = multiline;
		const isFile = type === 'file';
		const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
			const file = event.target.files ? event.target.files[0] : null;
			if (file) {
				setFileName(file.name);
				props?.handleHasFormFile(file);
			}

			// Call the onChange prop if one is provided
			if (onChange) {
				onChange(event);
			}
		};

		if (isFile) {
			return (
				<div className={cls(styles.fileInput, className)}>
					<label>
						<input
							type='file'
							name={name}
							defaultValue={defaultValue}
							placeholder={placeholder}
							accept={accept}
							required={required}
							onChange={handleFileChange}
							ref={ref as React.Ref<HTMLInputElement>}
							{...(props as InputHTMLAttributes<HTMLInputElement>)}
						/>
						<div className={styles.fileInputLabel}>
							<div className={styles.fileInputIcon}> </div>
							{fileName ? (
								<span className={styles.fileInputText} data-file-name={fileName}>
									{fileName?.length > 20 ? fileName.substring(0, 20) + '...' : fileName}
								</span>
							) : (
								<span className={styles.fileInputText} data-file-name={fileName}>
									No File Chosen
								</span>
							)}
						</div>
					</label>
				</div>
			);
		} else if (isTextarea) {
			return (
				<textarea
					className={cls(styles.input, className)}
					name={name}
					defaultValue={defaultValue}
					placeholder={placeholder}
					required={required}
					onChange={onChange}
					ref={ref as React.Ref<HTMLTextAreaElement>}
					{...(props as TextareaHTMLAttributes<HTMLTextAreaElement>)}
				/>
			);
		} else {
			return (
				<input
					className={cls(styles.input, className)}
					type={type}
					ref={ref as React.Ref<HTMLInputElement>}
					name={name}
					defaultValue={defaultValue}
					placeholder={placeholder}
					accept={accept}
					required={required}
					onChange={onChange}
					{...(props as InputHTMLAttributes<HTMLInputElement>)}
					{...(props.value && { value: props.value || '' })}
				/>
			);
		}
	}
);

Input.displayName = 'Input'; // Set displayName for debugging purposes

export default Input;
